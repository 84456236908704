import React from 'react';
import LandingPage from './components/LandingPage';  // Capital 'L' and 'P'

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;